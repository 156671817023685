import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_sms_recipient = _resolveComponent("tm-sms-recipient")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_sms_received_dropdown = _resolveComponent("sms-received-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection,
    expand: _ctx.expand,
    class: "contacts-table"
  }, {
    "body-cell-from-slot": _withCtx((props) => [
      _createVNode(_component_tm_sms_recipient, {
        content: props.row.to,
        to: _ctx.getTo(props.row.id)
      }, null, 8, ["content", "to"])
    ]),
    "body-cell-message-slot": _withCtx((props) => [
      (typeof props.row.message === 'object')
        ? (_openBlock(), _createBlock(_component_tm_badge, {
            key: 0,
            label: `${props.row.message.name}.${props.row.message.extension}`,
            icon: "tmi-people"
          }, null, 8, ["label"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(props.row.message), 1))
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_sms_received_dropdown, {
        sms: props.row
      }, null, 8, ["sms"])
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection", "expand"]))
}