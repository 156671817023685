
import type { PropType } from 'vue'
import { defineComponent, toRefs } from 'vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import useHistorySmsOpenModal from '@/compositions/history/useHistorySmsOpenModal'
import type { SMSReceivedSent } from '@/definitions/history/types'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmDropdown,
    TmDropdownItem,
    TmTableActionButton,
  },
  props: {
    sms: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
  setup(props) {
    const { sms } = toRefs(props)
    const { openModal } = useModals()
    const { openHistoryDeleteModal } = useHistorySmsOpenModal({
      lines: sms,
      type: 'received',
    })

    return {
      openModal,
      openHistoryDeleteModal,
    }
  },
})
