
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmSmsRecipient from '@/components/shared/TmSmsRecipient.vue'
import SmsReceivedDropdown from '@/components/pages/history/smsReceived/SmsReceivedDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SMSReceivedSent } from '@/definitions/history/types'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  components: {
    TmTable,
    TmBadge,
    SmsReceivedDropdown,
    TmSmsRecipient,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array as PropType<SMSReceivedSent[]>,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
    },
    expand: {
      type: Boolean,
    },
  },
  setup() {
    const getTo = (id: string): RouteLocationRaw => ({
      name: 'base.history.receivedSms.details',
      params: {
        id,
      },
    })

    return {
      getTo,
    }
  },
})
