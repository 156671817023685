
import {
  computed,
  defineComponent, ref
} from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import SmsFilter from '@/components/pages/history/SmsFilter.vue'
import SmsReceivedTable from '@/components/pages/history/smsReceived/SmsReceivedTable.vue'
import SmsSentEmpty from '@/components/pages/history/smsSent/SmsSentEmpty.vue'
import type { SMSReceivedSent } from '@/definitions/history/types'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import { getTableData } from '@/services/tableService'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    PageContent,
    SmsFilter,
    SmsReceivedTable,
    SmsSentEmpty,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const expand = ref(false)
    const { isEmptyMode } = useModes()

    const search = ref('')
    const selected = ref<SMSReceivedSent[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'From', value: 'from-slot' },
      {
        text: 'Message',
        value: 'message-slot',
        expandPossible: true,
        width: '60%',
        minWidth: '350px',
      },
      {
        text: 'Time received',
        value: 'sendTime',
        format: (val: string) => formatDate(val),
      },
    ])

    const tableItems = computed<SMSReceivedSent[]>(() => (getTableData('historySMS') as SMSReceivedSent[])
      .filter((line) => (line.historyType === 'received')))

    const tableItemsFiltered = computed(() => {
      if (!search.value) return tableItems.value
      return tableItems.value.filter((l) => l.message.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      expand,
      search,
      selected,
      tableHeaders,
      tableItemsFiltered,
      isEmptyMode,
    }
  },
})
